.s01{
    h1{
        color:#fff;
    }
}
.ressources-content{
    h1{
		width: 100%;
		text-transform: uppercase;
		text-align: center;
		font-size: 3rem;
		margin-right: 1rem;
		font-family: $Font-poppins;
		font-size: 40px;
		font-weight: 400;
        margin-top: 40px;
	}
    .card{
        box-shadow: 0px 4px 6px 2px rgba(0,0,0,0.30);
        &::after{
            content: "";
            color: black;
            position: absolute;
            top: 0;
            right: 0;
            width: 100px;
            height: 2px;
            background-color: $green;
        }
        &::before{
            content: "";
            color: black;
            position: absolute;
            top: 50px;
            right: -49px;
            width: 100px;
            height: 2px;
            background-color: $yellow;
            transform: rotate(90deg);
        }

        a.btn {
            background-color: $green;
            color:#fff;
            &:hover{
                background-color: $yellow;
            }
        }
    }
}