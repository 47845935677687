.about{
	padding-top: 5rem;
	padding-bottom: 2rem;
	margin-bottom: 2rem;
	.about-center {
		padding-top: 0rem;
		.about-section{
			display: flex;
			.description{
				padding-right: 1rem;
				display: flex;
				align-items: center;
				flex-basis: 70%;
				text-align: left;
				li{
					font-size: 1.2rem;
				}
			}
			.about-illustration{
				flex-basis: 30%;
				display: flex;
				img{
					max-width: 100%;
					margin: auto 0;
				}
			}
		}
		
	}
}

@media (max-width: 850px) {
	.about{
		height: unset;
		.about-center {
			.about-section{
				display: block;
				.description{
					padding-right: unset;
				}
				.about-illustration{
					display: none;
				}
			}
		}
	}
}