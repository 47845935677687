
// .footer{
// 	border-top: 1px solid whitesmoke;
// 	.footer-center{
// 		text-align: center;
// 		padding: .8rem 0;
// 		.footer-logo{
// 			max-width: 200px;
// 			margin-bottom: -.8rem;
// 		}
// 		.slogan{
// 			color: $green;
// 			margin-bottom: 1.5rem;
// 			font-size: .5rem;
// 		}
// 		p{
// 			margin-top: .5rem;
// 			color: $paragraph-color;
// 			font-size: .7rem;
// 			line-height: 16px;
// 			a{
// 				font-size: .7rem;
// 				color: rgb(0, 123, 205);
// 			}
// 		}
// 		.social-link{
// 			margin-bottom: 1.5rem;
// 			a{
// 				color: $dark-grey;
// 				cursor: pointer;
// 				transition: color ease-in-out 200ms;
// 				font-size: 1.4rem;
// 				&:hover{
// 					color: $green;
// 				}
// 			}
// 		}
// 		.links{
// 			display: flex;
// 			justify-content: center;
// 			align-items: center;
// 			.link{
// 				margin: 0 5px;
// 				color: $dark-grey;
// 				font-size: .8rem;
// 				text-decoration: underline;
// 				display: block;
	
// 			}
// 		}
// 	}
// 	.footer-icons{
// 		display: flex;
// 		align-items: center;
// 		justify-content: center;
// 	}
// }

// @media (min-width: 768px) {
// 	.footer{
// 		.footer-icons{
// 			display: none;
// 		}
// 	}

// }


.footer-48201 {
	padding: 4rem 0;
	background-color: $Navy;
	color: rgb(181, 181, 181);
	.footer-site-logo {
		img{
			margin-right: 20px


		}
		

	}
	.nav-links {
		li {
			margin-bottom: 10px;
			a {
				color: rgb(185, 185, 185);
				&:hover {
					color: $green;
				}
			}
		}
	}
	.btn {
		&.btn-tertiary {
			background-color: $green;
			color: white;
			border-radius: 5px;
			border: none;
			padding: 10px 20px;
			&:hover{
				background: $yellow;
			}
		}
	}
	.social {
		li {
			display: inline-block;
			a {
				font-size:35px;
				color: rgb(185, 185, 185);
				padding: 7px;
				&:hover {
					color: $green;
				}
				
			}
		}
	}
	.copyright {
		border-top: 1px solid rgb(185, 185, 185);
	}
	p{
		color: rgb(158, 158, 158);
	}
}
