// margins
$Frobot: 'Roboto', sans-serif;
$Font-poppins: 'Poppins', sans-serif;
$Small-margin: 1rem;
$Medium-margin: 2rem;
$Big-margin: 3rem;


//
$White:	#e6f1ff;
$Navy:#0a192f;
$dark-grey: #112B3C;
$paragraph-color: #253c4c;
$green: #67923d;
$yellow: rgb(255, 217, 0);