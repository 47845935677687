.privacy{
	.logo-container{
		display: flex;
		justify-content: center;
	}
	.title{
		text-align: center;
	}
	span{
		font-weight: bold;
	}
	.btn-container{
		display: flex;
		justify-content: center;
		margin: 1rem;
		.back-home{
			background: $green;
			padding: .5rem;
			border-radius: 5px;
			border: none;
			.link{
				color: white;
			}
		}
	}
}