@import "./variables";


nav{
	position: sticky;
	top: 0;
	background-color: white;
	z-index: 1;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	.navbar{
		display: flex;
		height: 73px;
		align-items: center;
		justify-content: space-between;
		.logo{
			img{
				max-width: 100px;
			}
		}
		.nav-links{
			flex-basis: 55%;
			justify-self: flex-end;
			display: flex;
			justify-content: space-between;
			align-items: center;
			// text-transform: capitalize;
			.link{
				// text-transform: uppercase;
				font-size: .9rem;
				font-family: $Font-poppins;
				font-weight: 300;
				color: $dark-grey;
				position: relative;
				letter-spacing: 1px;
				transition: color ease-in-out 300ms;
				&:hover{
					color: $green;
				}
				&:last-child {
					background: $green;
					color: white;
					padding: .5rem;
					border-radius: 5px;
					&:hover{
						background: $yellow;
					}
				}
				@media (max-width: 1050px) {
					font-size: .8rem;
				}
			}
			.dropdown {
				.dropdown-menu {
					color: $dark-grey;
					transition: all 0.5s;
					overflow: hidden;
					transform-origin: top center;
					transform: scale(1,0);
					display: block;
					.dropdown-item{
						color: $dark-grey;
						&:hover {
							background:$green;
							color:white;
						}
			
					}
				}
				&:hover {
					.dropdown-menu {
						transform: scale(1);
					}
				}
			}
		}
	}



		
	
	.burger{
		display: none;
	}
}

.dropdown {
    .dropdown-menu {
		color: $dark-grey;
        transition: all 0.5s;
        overflow: hidden;
        transform-origin: top center;
        transform: scale(1,0);
        display: block;
		.dropdown-item{
			color: $dark-grey;
			&:hover {
				background:$green;
				color:white;
			}

		}
    }
    &:hover {
        .dropdown-menu {
            transform: scale(1);
        }
    }
}






@media (max-width: 950px) {
	nav{
		.navbar{
			.link{
				display: none;
			}
			.burger{
				display: block;
				font-size: 2rem;
				cursor: pointer;
				transition: all ease-in-out 250ms;
				color: $dark-grey;
			}
		}
	}
}