
@import "./navbar";
@import "./sidebar";
@import "./about";
@import "./home";
@import "./services";
@import "./contact";
@import "./footer";
@import "./mention";
@import "./privacy";
@import "./variables";
@import "./annuairePro";
@import "./ressources";
@import "./myNav";

html{
	scroll-behavior: smooth;
}

*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	list-style: none;
	text-decoration: none;
	font-family: 'Roboto', sans-serif;
	
	// color: $White;
}

body{
	background-color: white;
	color: $dark-grey;
	background-color: aliceblue;
	
}

a{
	font-family: $Frobot;
	letter-spacing: 1px;
	// color: $Lightest-Slate;
	font-size: 1.1rem;
	text-decoration: none !important;
}
p {
	font-family: $Font-poppins;
	letter-spacing: 1px;
	font-size: 1rem;
	line-height: 30px;
	color: $paragraph-color;
}

@media (max-width: 768px) {
	p{
		font-size: 1rem;
	}
}
.container{
	width: 100%;
	margin: 0 auto;
}
@media (min-width: 1200px) {
	.container{
		width: 1200px;
		padding:  0 3rem;
		
	}
}
@media (max-width: 1200px) {
	.container{
		width: 100%;
		padding:  0 3.5rem;
	}
}
@media (max-width: 768px) {
	.container{
		padding:  0 2rem;
	}
}
@media (max-width: 568px) {
	.container{
		padding:  0 1rem;
	}
}

.section-title{
	margin-bottom: $Small-margin;
	display: flex;
	align-items: center;
	h1{
		width: 100%;
		text-transform: uppercase;
		text-align: center;
		font-size: 3rem;
		margin-right: 1rem;
		font-family: $Font-poppins;
		font-size: 40px;
		font-weight: 400;
	}
}

@media (max-width: 768px) {
	.section-title{
		h1{
			font-size: 2rem;
		}
	}
}



// Scroll fade-in animation style 
.reveal{
	position: relative;
	transform: translateY(50px);
	opacity: 0;
	transition: all ease-in-out 700ms;
}

.reveal.active{
	transform: translateY(0px);
	opacity: 1;

}