@import "./variables";
.s01 {

    background:linear-gradient( rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.20) ), url("../../public/images/img-bg.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	height: 50vh;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 2rem;
	position: relative;
	margin-bottom: 20px;
	.home-btn{
		position: absolute;
		bottom: 0px;
		left: 50px;
		background: white;
		padding: 1rem;
		border: none;
		border-radius: 5px 5px 0 0;
		a{
			color: $dark-grey;
			font-family: $Font-poppins;
			// text-transform: capitalize;
		}
		&::after{
			content: "";
			width: 20%;
			height: 3px;
			background-color: $green;
			bottom: 5px;
			border-radius: 2px;
			left: 5%;
			position: absolute;
			transition: width ease-in-out 250ms;
		}
		&:hover::after{
			width: 90%;
		}
	}
	.modal{
		.btn{
			background-color: $green;
			color:#fff;
			// padding: 1rem;
			padding-left: 2rem;
			padding-right: 2rem;
		}
	}
	form {
		width: 100%;
		max-width: 1290px;
		
		
        fieldset{
            border:none;
            legend {
                font-size: 30px;
                line-height: 40px;
                font-weight:800;
                color: #fff;
                margin-bottom: 60px;
                border: none;
                text-align: center;
            }
        }


		
		.inner-form {
			
			// padding: 50px 47px;
			display: -ms-flexbox;
			display: flex;
			width: 87%;
			-ms-flex-pack: justify;
			justify-content: space-between;
			-ms-flex-align: center;
			align-items: center;
			// margin-left: 85px;
			background: #fff;
			border-radius: 40px;
			
            // border: solid 5px #fff;
			.input-field {
				// margin-right: 30px;
				height: 73px;
				input {
					height: 100%;
					background: #fff;
					// border-radius: 5px;
					border: 0;
					display: block;
					width: 100%;
					padding: 10px 32px;
					font-size: 20px;
					&:-moz-placeholder {
						color: #9a9a9a;
						font-size: 20px;
					}
					&::-webkit-input-placeholder {
						color: #c1c1c1;
						font-size: 20px;
					}
					&:hover {
						box-shadow: none;
						outline: 0;
					}
					&:focus {
						box-shadow: none;
						outline: 0;
					}
				}
				input.placeholder {
					color: #9a9a9a;
					font-size: 20px;
				}
				select{
					color: #9a9a9a;
					padding: 10px 32px;
					height: 100%;
					background: #fff;
					border: 0;
					display: block;
					width: 100%;
					padding: 10px 32px;
					font-size: 20px;
					// option{
					// 	color: $Navy;
					// 	&:hover{
					// 		background-color:$green;
					// 		color: #fff;
					// 	}
					// }
					
				}
				
			}
			// .input-field.first-wrap {
			// 	-ms-flex-positive: 1;
			// 	flex-grow: 1;
			// }
			.input-field.first-wrap {
				// width: 30%;
			 	width: 450px;
                -ms-flex-positive: 1;
				flex-grow: 1;
				input{
					border-top-left-radius: 40px;
					border-bottom-left-radius: 40px;
					outline: none;
				}
				
			}
			.input-field.second-wrap {
				
				// width: 180px;
                -ms-flex-positive: 1;
				flex-grow: 1;
				border-left: solid 1px #cdcccc;
				margin-right: 10px;
				select {
					option{
						color: $Navy;
						&:hover{
							background: $green;
							color: #fff;
						}
					}
				}
			}
			.input-field.third-wrap {
				// width: 25%;
				// min-width: 180px;
                -ms-flex-positive: 1;
				flex-grow: 1;
				border-left: solid 1px #cdcccc;
				margin-right: 15px;
				option {
					color: $Navy;
					&:hover {
						background:$green;
						color: #fff;
					}
				}
			}
			.input-field.fourth-wrap {
				width: 220px;
				margin-right: 0;
				margin-left: 15px;
				.btn-search {
					margin-top: 7px;
					height: 81%;
					width: 100%;
					background: $green;
					white-space: nowrap;
					border-radius:30px;
					// border-top-right-radius: 10px;
					// border-bottom-right-radius: 10px;
					font-size: 20px;
					color: #fff;
					transition: all .2s ease-out, color .2s ease-out;
					border: 0;
					cursor: pointer;
					&:hover {
						background: $yellow;
					}
				}
			}
		}
	}
}

.content{
	margin-top:100px;
	margin-bottom: 100px;
	.card {
		border-radius:5px;
		margin:10px auto;
		border-color: #fff;
		background: #fff;
		box-shadow: 0px 4px 6px 2px rgba(0,0,0,0.30);
		width: 600px;
	}
	.card-title {
		text-transform: uppercase;
		color: $dark-grey
	}
	
	.card-text {
		margin-top:10px;
		margin-bottom: 10px;
		background-color:#FFFFFF;
		color: $Navy;
	}
	a.btn {
		background-color: $green;
		color:#fff;
		&:hover{
			background-color: $yellow;
		}
	}
	hr {
	  margin-top: 1rem;
	  margin-bottom: 1rem;
	  border: 0;
	  border-top: 1px solid rgba(0, 0, 0, 0.1);
	}

	
}


@media screen and (max-width: 992px) {
	.s01 {
		form {
			legend {
				font-size: 50px;
				text-align: center;
				margin-bottom: 50px;
			}
			.inner-form {
				padding: 30px 35px;
				.input-field {
					height: 50px;
				}
			}
		}
	}
}
@media screen and (max-width: 767px) {
	.s01 {
		form {
			legend {
				font-size: 40px;
				margin-bottom: 40px;
			}
			.inner-form {
				-ms-flex-wrap: wrap;
				flex-wrap: wrap;
				padding: 20px;
				justify-self: center;
				.input-field {
					margin-right: 0;
					align-self: center;
					input {
						padding: 5px 15px;
						font-size: 16px;
					}
				}
				.input-field.first-wrap {
					width: 100%;
					margin-bottom: 20px;
					border: solid 1px #cdcccc;
					border-radius: 5px;
				}
				.input-field.second-wrap {
					width: calc(50% - 10px);
					min-width: auto;
					margin-bottom: 20px;
					border: solid 1px #cdcccc;
					border-radius: 5px;
				}
				.input-field.third-wrap {
					width: calc(50% - 10px);
					border: solid 1px #cdcccc;
					border-radius: 5px;
				}
			}
		}
	}
}